<template>
    <div>
        <v-card flat style="max-width: 800px">
            <v-card-text>
                <v-data-table :headers="deltakerFelter" :items="deltakere" no-data-text="Ingen deltakere." :items-per-page="-1" must-sort hide-default-footer>
                    <template v-slot:header.rediger>
                        <a v-if="hasFullAccessGriefGroup()" v-on:click="toggleParticipants" v-text="visAvmeldte ? 'Skjul sluttet' : 'Vis sluttet'"></a>
                    </template>
                    <template v-slot:item.fulltNavn="{ index, item }">
                        <td v-on:click="edit(index)" class="v-data-table-item--link">
                            <a
                                v-if="item.person.fulltNavn"
                                v-html="item.person.fulltNavn"
                                v-bind:style="{ textDecoration: item.fjernet ? 'line-through' : 'none' }"
                            ></a>
                            <span
                                v-else
                                v-html="item.person.fulltNavn || 'Slettet bruker'"
                                v-bind:style="{ textDecoration: item.fjernet ? 'line-through' : 'none' }"
                            ></span>
                        </td>
                    </template>
                    <template v-slot:item.status="{ item }">
                        <span v-html="item.person.statusNavn"></span>
                    </template>
                    <template v-slot:item.lagtTil="{ item }">
                        <span v-html="item.lagtTil.format('DD.MM.YYYY')"></span>
                    </template>
                    <template v-slot:item.rediger="{ item }">
                        <template v-if="hasFullAccessGriefGroup()">
                            <span v-if="item.fjernet" v-html="item.fjernet.format('DD.MM.YYYY')"></span>
                            <div v-else class="d-flex justify-center">
                                <v-icon v-if="!item.fjernet" small v-on:click="remove(item)">mdi-delete</v-icon>
                            </div>
                        </template>
                    </template>
                    <template v-slot:foot v-if="hasFullAccessGriefGroup()">
                        <tfoot>
                            <tr>
                                <td colspan="4" style="height: auto">
                                    <div class="pt-4 d-flex align-center">
                                        <v-autocomplete
                                            label="Velg bruker"
                                            v-model="nyDeltaker"
                                            :items="alleDeltakere"
                                            item-text="fulltNavn"
                                            item-value="id"
                                            hide-details
                                        ></v-autocomplete>
                                        <v-btn outlined class="mt-4 ml-2" v-on:click="add"> Legg til </v-btn>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <person-dialog ref="personDialog" v-model="person" rolle="BRUKER_SORG" :personer="personer"></person-dialog>
    </div>
</template>
s
<script>
import { mapActions, mapGetters } from 'vuex';
import PersonDialog from '@/pages/persons/PersonDialog.vue';

export default {
    name: 'GriefGroupTabParticipants',

    props: {
        value: {
            type: Object,
        },
    },
    components: {
        PersonDialog,
    },
    data() {
        return {
            gruppe: {},

            visAvmeldte: false,
            deltakere: [],

            nyDeltaker: '',
            alleDeltakere: [],

            deltakerFelter: [
                { text: 'Navn', value: 'fulltNavn', sortable: false },
                { text: 'Status', value: 'status', sortable: false },
                { text: 'Oppstart', value: 'lagtTil', sortable: true, width: '150px' },
                { text: 'Vis sluttet', value: 'rediger', sortable: false, width: '130px' },
            ],

            personer: [],
            person: {},
        };
    },
    computed: {
        ...mapGetters('api', ['hasFullAccessGriefGroup']),
    },
    filters: {
        format: function (value, format) {
            return value.format(format);
        },
    },

    /**
     * created
     */
    created: async function () {
        if (!this.hasFullAccessGriefGroup()) {
            this.deltakerFelter.pop();
        }
        this.gruppe = this.value;
        await this.update();
    },

    methods: {
        ...mapActions('api', ['request', 'formatGriefGroup', 'formatPerson']),
        ...mapActions(['snackbar', 'confirm']),

        /**
         * update
         */
        update: async function (gruppe) {
            console.log(gruppe);
            if (gruppe) {
                this.gruppe = await this.formatGriefGroup(gruppe);
                this.$emit('input', gruppe);
            }

            const deltakerIds = [];
            this.deltakere = [];
            this.personer = [];
            if (this.gruppe) {
                if (this.gruppe.deltakere) {
                    for (const deltaker of this.gruppe.deltakere) {
                        if (this.visAvmeldte || !deltaker.fjernet) {
                            this.deltakere.push(deltaker);
                            this.personer.push(deltaker.person);

                            deltakerIds.push(deltaker.person.id);
                        }
                    }
                }
            }

            // personer
            let response = await this.request({
                method: 'get',
                url: '/person/bruker/sorg',
            });
            if (response && typeof Array.isArray(response)) {
                this.alleDeltakere = [];
                for (const person of response) {
                    if (!deltakerIds.find((id) => id == person.id)) {
                        this.alleDeltakere.push(await this.formatPerson(person));
                    }
                }
            }
        },

        /**
         * toggleParticipants
         **/
        toggleParticipants: function () {
            this.visAvmeldte = !this.visAvmeldte;
            this.update();
        },

        /**
         * add
         */
        add: async function () {
            if (!this.nyDeltaker) {
                console.log('TODO');
            } else {
                const data = new FormData();
                data.append('personId', [this.nyDeltaker]);

                const response = await this.request({
                    method: 'post',
                    url: '/sorggruppe/' + this.gruppe.id + '/deltaker',
                    data,
                });
                if (!response) {
                    console.log('TODO: error');
                } else {
                    this.update(response);
                    this.nyDeltaker = null;
                }
            }
        },

        /**
         * remove
         */
        remove: async function (item) {
            if (await this.confirm({ title: 'Fjerne?', message: 'Sikker på at du vil fjerne ' + item.person.fulltNavn + ' fra gruppen?' })) {
                const data = new FormData();
                data.append('personId', [item.person.id]);

                const response = await this.request({
                    method: 'delete',
                    url: '/sorggruppe/' + this.gruppe.id + '/deltaker',
                    data,
                });
                if (!response) {
                    console.log('TODO: error');
                } else {
                    this.update(response);
                }
            }
        },

        /**
         * edit
         */
        edit: function (index) {
            if (!this.personer[index].arkivert) {
                this.$refs.personDialog.edit(index);
            }
        },
    },
};
</script>
