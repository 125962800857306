var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticStyle:{"max-width":"800px"},attrs:{"flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.deltakerFelter,"items":_vm.deltakere,"no-data-text":"Ingen deltakere.","items-per-page":-1,"must-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header.rediger",fn:function(){return [(_vm.hasFullAccessGriefGroup())?_c('a',{domProps:{"textContent":_vm._s(_vm.visAvmeldte ? 'Skjul sluttet' : 'Vis sluttet')},on:{"click":_vm.toggleParticipants}}):_vm._e()]},proxy:true},{key:"item.fulltNavn",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('td',{staticClass:"v-data-table-item--link",on:{"click":function($event){return _vm.edit(index)}}},[(item.person.fulltNavn)?_c('a',{style:({ textDecoration: item.fjernet ? 'line-through' : 'none' }),domProps:{"innerHTML":_vm._s(item.person.fulltNavn)}}):_c('span',{style:({ textDecoration: item.fjernet ? 'line-through' : 'none' }),domProps:{"innerHTML":_vm._s(item.person.fulltNavn || 'Slettet bruker')}})])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.person.statusNavn)}})]}},{key:"item.lagtTil",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.lagtTil.format('DD.MM.YYYY'))}})]}},{key:"item.rediger",fn:function(ref){
var item = ref.item;
return [(_vm.hasFullAccessGriefGroup())?[(item.fjernet)?_c('span',{domProps:{"innerHTML":_vm._s(item.fjernet.format('DD.MM.YYYY'))}}):_c('div',{staticClass:"d-flex justify-center"},[(!item.fjernet)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v("mdi-delete")]):_vm._e()],1)]:_vm._e()]}},(_vm.hasFullAccessGriefGroup())?{key:"foot",fn:function(){return [_c('tfoot',[_c('tr',[_c('td',{staticStyle:{"height":"auto"},attrs:{"colspan":"4"}},[_c('div',{staticClass:"pt-4 d-flex align-center"},[_c('v-autocomplete',{attrs:{"label":"Velg bruker","items":_vm.alleDeltakere,"item-text":"fulltNavn","item-value":"id","hide-details":""},model:{value:(_vm.nyDeltaker),callback:function ($$v) {_vm.nyDeltaker=$$v},expression:"nyDeltaker"}}),_c('v-btn',{staticClass:"mt-4 ml-2",attrs:{"outlined":""},on:{"click":_vm.add}},[_vm._v(" Legg til ")])],1)])])])]},proxy:true}:null],null,true)})],1)],1),_c('person-dialog',{ref:"personDialog",attrs:{"rolle":"BRUKER_SORG","personer":_vm.personer},model:{value:(_vm.person),callback:function ($$v) {_vm.person=$$v},expression:"person"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }